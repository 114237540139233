import React, { memo, useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { LazyLoadImage } from 'react-lazy-load-image-component';

// import ProductBreadcrumb from "./productBreadcrumb";

const Category = (props) => {
  const [categories, setCategories] = useState([]);
 
  useEffect(() => {
console.log("props_responsive",props.responsive);
    // let mounted = true;
    // setCategories(categoriesData);

    axios
      .get(`${process.env.REACT_APP_BASE_URL}getAllCategory`)
      .then(function (response) {
        // handle success
        // if (mounted) {
          setCategories(response.data.data);
        // }

        // bindCategory();
      })
      .catch(function (error){
        // handle error
        console.log(error);
      });
    // return () => (mounted = false);
  }, []);

  let responsive1 = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  return (
    <>


      <div className="container-fluid mt-5 mb-5">
        <h4 className="gift-ideas-heading mt-3">Categories</h4>
        <p className="text-tertiary">
          Wide range of gifts for your Celebration
        </p>
        <div className="row">
        <Carousel responsive={(props.Response)? props.Response: responsive1}>
         
          {categories.length > 0 &&
            categories.map((item ,index) => (
             
              <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12"  key={index} style={{width:'100%'}}
              >
                
                <Link  to={`/category/${item.slug}`} style={{textDecoration:"none",color:"black"}}>
                <div className="category_div m-1 ">
               
                  <div className="row w-100">
                
                    <div className="col">
                      <LazyLoadImage
                        src={item.icon}
                        className="img-fluid category_img"
                        alt="..."
                      
                      />
                    </div>
                    <div className="col">
                      <h6>{item.name}</h6>
                      <p className="category_para">{item.description}</p>
                    </div>
                   
                  </div>
                
                </div>
                </Link>
              
              </div>
           
            ))}

</Carousel>
        </div>
      </div>
      

    </>
  );
};
export default memo(Category);
